<div class="root">
  <div class="intro">
    <div class="choice">
      <h1 class="why-shohay-is">{{ "Usp.why-shohay" | translate }}</h1>
      <div class="at-shohay-we">
        {{ "Usp.at-shohay" | translate }}
      </div>
    </div>
    <button class="blue-button-md">{{ "Usp.Show" | translate }}</button>
  </div>
  <div class="solution">
    <div class="features" style="margin-top: 0px">
      <img
        class="features-child"
        loading="lazy"
        alt=""
        src="../../../assets/images/home_page/hp-6/ucp-1.png"
      />

      <div class="feature-description">
        <h3 class="all-in-one-solution">{{ "Usp.all-in-one" | translate }}</h3>
        <div class="shohay-centralizes-all">
          {{ "Usp.shohay-centralizes-all" | translate }}
        </div>
      </div>
    </div>
    <div class="features">
      <img
        class="features-child"
        loading="lazy"
        alt=""
        src="../../../assets/images/home_page/hp-6/ucp-1.png"
      />

      <div class="feature-description">
        <h3 class="all-in-one-solution">{{ "Usp.Total" | translate }}</h3>
        <div class="shohay-centralizes-all">
          {{ "Usp.Customize" | translate }}
        </div>
      </div>
    </div>
    <div class="features">
      <img
        class="features-child"
        loading="lazy"
        alt=""
        src="../../../assets/images/home_page/hp-6/usp-3.png"
      />

      <div class="feature-description">
        <h3 class="all-in-one-solution">{{ "Usp.Local" | translate }}</h3>
        <div class="shohay-centralizes-all">
          {{ "Usp.shohay-centralizes" | translate }}
        </div>
      </div>
    </div>
    <div class="features">
      <img
        class="features-child"
        loading="lazy"
        alt=""
        src="../../../assets/images/home_page/hp-6/usp-4.png"
      />

      <div class="feature-description">
        <h3 class="all-in-one-solution">
          {{ "Usp.Click" | translate }}
        </h3>
        <div class="shohay-centralizes-all">
          {{ "Usp.Before" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
