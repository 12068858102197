<div class="root">
  <div class="we-are-trusted-by-wrapper">
    <div class="we-are-trusted">
      {{ "Partners.we-are-trusted" | translate }}
    </div>
  </div>
  <section class="logo-banner">
    <div class="logo-1-wrapper">
      <div class="img-wrapper">
        <img
          class="logo-1-icon"
          loading="lazy"
          alt=""
          [ngSrc]="'../../../assets/images/home_page/hp-7/logo-1@2x.png'"
          width="200"
          height="50"
          
        />
      </div>
    </div>

    <img
      class="caritas-bangladesh-logo-e9e926-icon"
      loading="lazy"
      alt=""
      [ngSrc]="
        '../../../assets/images/home_page/hp-7/caritasbangladeshlogoe9e9263441seeklogo-2@2x.png'
      "
      width="200"
      height="50"
      
    />

    <div class="image-1-wrapper">
      <img
        class="image-1-icon"
        loading="lazy"
        alt=""
        [ngSrc]="'../../../assets/images/home_page/hp-7/image-1@2x.png'"
        width="200"
        height="50"
        
      />
    </div>

    <img
      class="image-2-icon"
      loading="lazy"
      alt=""
      [ngSrc]="'../../../assets/images/home_page/hp-7/image-2@2x.png'"
      width="200"
      height="50"
      
    />

    <img
      class="image-3-icon"
      loading="lazy"
      alt=""
      [ngSrc]="'../../../assets/images/home_page/hp-7/image-3@2x.png'"
      width="200"
      height="50"
      
    />

    <div class="image-4-wrapper">
      <img
        class="image-4-icon"
        loading="lazy"
        alt=""
        [ngSrc]="'../../../assets/images/home_page/hp-7/image-4@2x.png'"
        width="200"
        height="50"
        
      />
    </div>

    <img
      class="image-5-icon"
      loading="lazy"
      alt=""
      [ngSrc]="'../../../assets/images/home_page/hp-7/image-5@2x.png'"
      width="200"
      height="50"
      
    />
  </section>
</div>
