<div class="root">
  <section class="container row">
    <div class="container1 col-12">
      <div class="container2">
        <h1 class="empowerment-tools-for">
          {{ "Ngo-tools.empowerment-tools" | translate }}
        </h1>
      </div>
      <div class="explore-our-dynamic">
        {{ "Ngo-tools.explore-our" | translate }}
      </div>
    </div>
  </section>
  <div class="row" style="width: 100%">
    <section class="container3 col-12">
      <div class="formation row pb-3 text-center">
        <div class="formation-grid col-md-3 col-sm-6">
          <img
            class="formation-grid-child"
            loading="lazy"
            alt=""
            src="../../../assets/images/home_page/hp-5/group-2@2x.png"
          />

          <h3 class="ngo-formation">
            {{ "Ngo-tools.ngo-formation" | translate }}
          </h3>
          <div class="simplify-your-registration">
            {{ "Ngo-tools.simplify-your" | translate }}
          </div>
        </div>
        <div class="formation-grid col-md-3 col-sm-6">
          <img
            class="formation-grid-child"
            loading="lazy"
            alt=""
            src="../../../assets/images/home_page/hp-5/group-3-1@2x.png"
          />

          <h3 class="ngo-formation">
            {{ "Ngo-tools.Fundraising" | translate }}
          </h3>
          <div class="simplify-your-registration">
            {{ "Ngo-tools.Gather" | translate }}
          </div>
        </div>
        <div class="formation-grid col-md-3 col-sm-6">
          <img
            class="formation-grid-child"
            loading="lazy"
            alt=""
            src="../../../assets/images/home_page/hp-5/group-3-2@2x.png"
          />

          <h3 class="ngo-formation">
            {{ "Ngo-tools.Field" | translate }}
          </h3>
          <div class="simplify-your-registration">
            {{ "Ngo-tools.Gather" | translate }}
          </div>
        </div>
        <div class="formation-grid col-md-3 col-sm-6">
          <img
            class="formation-grid-child"
            loading="lazy"
            alt=""
            src="../../../assets/images/home_page/hp-5/group-3-4@2x.png"
          />

          <h3 class="ngo-formation">
            {{ "Ngo-tools.Project" | translate }}
          </h3>
          <div class="simplify-your-registration">
            {{ "Ngo-tools.Design" | translate }}
          </div>
        </div>
      </div>
    </section>
    <section class="reporting col-12">
      <div class="formation row pb-3 text-center">
        <div class="formation-grid col-md-3 col-sm-6">
          <img
            class="formation-grid-child"
            loading="lazy"
            alt=""
            src="../../../assets/images/home_page/hp-5/group-3-3@2x.png"
          />

          <h3 class="ngo-formation">
            {{ "Ngo-tools.Reporting" | translate }}
          </h3>
          <div class="simplify-your-registration">
            {{ "Ngo-tools.empowerment-tools" | translate }}
          </div>
        </div>
        <div class="formation-grid col-md-3 col-sm-6">
          <img
            class="formation-grid-child"
            loading="lazy"
            alt=""
            src="../../../assets/images/home_page/hp-5/group-3-3@2x.png"
          />

          <h3 class="ngo-formation">
            {{ "Ngo-tools.Stakeholder" | translate }}
          </h3>
          <div class="simplify-your-registration">
            {{ "Ngo-tools.empowerment-tools" | translate }}
          </div>
        </div>
        <div class="formation-grid col-md-3 col-sm-6">
          <img
            class="formation-grid-child"
            loading="lazy"
            alt=""
            src="../../../assets/images/home_page/hp-5/group-3-6@2x.png"
          />

          <h3 class="ngo-formation">
            {{ "Ngo-tools.Financial" | translate }}
          </h3>
          <div class="simplify-your-registration">
            Manage budgets, track expenses, and ensure financial transparency.
          </div>
        </div>
        <div class="formation-grid col-md-3 col-sm-6">
          <img
            class="formation-grid-child"
            loading="lazy"
            alt=""
            src="../../../assets/images/home_page/hp-5/group-3-4@2x.png"
          />

          <h3 class="ngo-formation">
            {{ "Ngo-tools.Member" | translate }}
          </h3>
          <div class="simplify-your-registration">
            Organize member information, gauge participation, and enhance
            interactions.
          </div>
        </div>
      </div>
      <div></div>
      <div class="reporting-child"></div>
    </section>
  </div>
  <div class="overflow-button">
    <button class="blue-button-md">Show More</button>
  </div>
</div>
