import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';

@Component({
  selector: 'app-hp2-article-news',
  templateUrl: './hp2-article-news.component.html',
  styleUrls: ['./hp2-article-news.component.css'],
})
export class Hp2ArticleNewsComponent implements OnInit {
  @ViewChild('articleButton') articleButton!: ElementRef;
  @ViewChild('newsButton') newsButton!: ElementRef;

  private articleNewsOriginalList: any[] = [];
  public articleNewsList: any[] = [];
  
  constructor(
    private router: Router,
    private readonly shohayBlogService: ShohayBlogService
  ) {}

  ngOnInit(): void {
    this.shohayBlogService.getArticleNews().subscribe((result) => {
      this.articleNewsOriginalList = result
        .splice(0, 4);
      this.articleNewsList = JSON.parse(
        JSON.stringify(this.articleNewsOriginalList)
      );
    });
  }

  public toggleArticleNews(
    filterValue: string,
    class1: string,
    class2: string
  ): void {
    this.newsButton.nativeElement.classList.remove(class1);
    this.newsButton.nativeElement.classList.add(class2);
    this.articleButton.nativeElement.classList.remove(class2);
    this.articleButton.nativeElement.classList.add(class1);

    this.articleNewsList = this.articleNewsOriginalList.filter(
      (article) => article.type === filterValue
    );
  }

  public goToArticleNews(id: number, type: string): void {
    console.log(type);
    if (type === 'Article') {
      this.router.navigate(['/article', id]);
    } else {
      this.router.navigate(['/news', id]);
    }
  }
}
