<div class="root">
  <h3 class="discover-shohay">Discover Shohay</h3>
  <div class="empowering-ngos-in">
    Empowering NGOs in Bangladesh and offering NGO services and documentaries
  </div>
  <section class="navigation">
    <div class="action-buttons1">
      <h3 class="learn">Learn</h3>
      <div class="search-donate-manage">
        <div class="search">
          <!-- loading="lazy" -->
          <img
            class="adobestock-vde76zrqmo-icon"
            alt=""
            [ngSrc]="'../../../../../assets/images/discover-shohay/Search.png'"
            width="200"
            height="200"
            priority
          />
        </div>
      </div>
      <div class="get-to-know">Get to know your favorite NGO in depth</div>
      <div class="explore-detailed-insights">
        Explore detailed insights into the NGOs you care about.
      </div>
    </div>
    <div class="action-buttons1">
      <h3 class="donate">Donate</h3>
      <div class="donate-wrapper">
        <div class="donate1">
          <!-- loading="lazy" -->
          <img
            class="mutual-donation-5750436-481796-icon"
            alt=""
            [ngSrc]="'../../../../../assets/images/discover-shohay/Donate.png'"
            width="200"
            height="200"
            priority
          />
        </div>
      </div>
      <div class="donate-securely-to">Donate securely to registered NGOs</div>
      <div class="support-ngos-through">
        Support NGOs through a secure donation process.
      </div>
    </div>
    <div class="action-buttons1">
      <h3 class="operate">Operate</h3>
      <div class="manage-wrapper">
        <div class="manage">
          <!-- loading="lazy" -->
          <img
            class="getpaidstockcom-6692ae35da3a-icon"
            [ngSrc]="'../../../../../assets/images/discover-shohay/Manage.png'"
            alt=""
            width="200"
            height="200"
            priority
          />
        </div>
      </div>
      <div class="manage-your-ngo">
        Manage your NGO fairly, easily and easily
      </div>
      <div class="simplify-and-streamline">
        Simplify and streamline NGO management with our user-friendly tools.
      </div>
    </div>
  </section>
</div>
