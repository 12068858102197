import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { delay, Observable, of, Subscription } from 'rxjs';
import { I18nServiceService } from 'src/app/shared/shared-services/i18n-service/i18n-service.service';

@Component({
  selector: 'app-hp1-hero',
  templateUrl: './hp1-hero.component.html',
  styleUrls: ['./hp1-hero.component.css'],
})
export class Hp1HeroComponent implements OnInit {
  ngoSearchQuery: string = '';
  public searchButton: any;
  private langChangeSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.updateSearchIcon();
      }
    );
    this.updateSearchIcon();

   
  }


  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollPosition =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const navbarElement = document.getElementById('hero') as HTMLElement;

    if (scrollPosition > 30) {
      navbarElement.classList.add('add-margin');
    } else {
      navbarElement.classList.remove('add-margin');
    }
  }

  public onSearchClick(): void {
    if (!this.ngoSearchQuery) {
      return;
    }

    this.router.navigateByUrl('/search-ngo?query=' + this.ngoSearchQuery);
  }

  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/find_icon.svg',
    stylingMode: 'text',
  };

  private updateSearchIcon(): void {
    this.searchButton = {
      text: this.translate.instant('Hero.Search'),
      stylingMode: 'text',
      onClick: () => {
        this.onSearchClick();
      },
    };
  }
}
