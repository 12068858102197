export class NewsModel {
  id: number = null;
  topic: string = null;
  title: string = null;
  type: string = null;
  introduction: string = null;
  conclusion: string = null;
  createdAt: Date = null;
  publishedAt: Date = null;
  imageUrl: string = null;
  updatedAt: Date = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}
