import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hp4-sign-up',
  templateUrl: './hp4-sign-up.component.html',
  styleUrls: ['./hp4-sign-up.component.css']
})
export class Hp4SignUpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
