<div class="root">
  <div class="flood-relief-fund">
    <div class="banner">
      <img
        class="mask-group-icon"
        loading="lazy"
        alt=""
        [src]="campaigns[0]?.appeal_img_url"
      />

      <div class="banner-shape"></div>
    </div>
    <div class="title">
      <div class="wounded-students-assistance">
        {{ campaigns[0]?.appeal_subtitle }}
      </div>
    </div>
    <h1 class="emergency-flood-appeal-container">
      <p class="emergency">{{ campaigns[0]?.title }}</p>
    </h1>
    <div
      class="yellow-button-lg"
      [routerLink]="['/donation']"
      [queryParams]="{ id: '0' }"
    >
      Donate Now
    </div>
  </div>
  <div class="sudents-assistance-fund">
    <div class="banner">
      <img
        class="image-222-icon"
        loading="lazy"
        alt=""
        [src]="campaigns[1]?.appeal_img_url"
      />

      <div class="frame-child"></div>
    </div>
    <div class="wounded-students-assistance-fu-wrapper">
      <div class="wounded-students-assistance">
        {{ campaigns[1]?.appeal_subtitle }}
      </div>
    </div>
    <h1 class="aid-appeal-for-container">
      <p class="emergency">{{ campaigns[1]?.title }}</p>
    </h1>
    <div
      class="yellow-button-lg"
      [routerLink]="['/donation']"
      [queryParams]="{ id: '1' }"
    >
      Donate Now
    </div>
  </div>

  <!-- <swiper slides-per-view="3" speed="500" loop="true" css-mode="true">
    <swiper-slide>Slide 1</swiper-slide>
    <swiper-slide>Slide 2</swiper-slide>
    <swiper-slide>Slide 3</swiper-slide>
    ...
  </swiper> -->
  <!-- <div class="swiper mySwiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide">Slide 1</div>
      <div class="swiper-slide">Slide 2</div>
      <div class="swiper-slide">Slide 3</div>
      <div class="swiper-slide">Slide 4</div>
      <div class="swiper-slide">Slide 5</div>
      <div class="swiper-slide">Slide 6</div>
      <div class="swiper-slide">Slide 7</div>
      <div class="swiper-slide">Slide 8</div>
      <div class="swiper-slide">Slide 9</div>
    </div>
  </div> -->

  <!-- <gallery>
    <ng-container *galleryImageDef="let item; let active = active">
      <div *ngIf="active" class="item-text">
        {{ item?.alt }}
      </div>
    </ng-container>
  </gallery> -->
</div>
<div class="indicator_div">
  <div style="width: 33.333%"></div>
  <div class="indicator">
    <div
      [ngClass]="isActive(0) ? 'blue_bar' : 'yellow_circle'"
      (click)="setActive(0)"
    ></div>
    <div
      [ngClass]="isActive(1) ? 'blue_bar' : 'yellow_circle'"
      (click)="setActive(1)"
    ></div>
  </div>
  <div class="see_all" routerLink="/all-relief-campaigns">See All</div>
</div>
