import { Component } from '@angular/core';
import { CampaignsService } from 'src/app/shared/services/campaign/campaigns.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-donation-buttons',
  templateUrl: './donation-buttons.component.html',
  styleUrl: './donation-buttons.component.scss',
})
export class DonationButtonsComponent {
  public campaigns: any[] = [];
  private activeIndex = 0;

  public dataSource: any = [
    {
      imageAlt: 'Image 1',
      imageSrc: '../../../../assets/Slide-img-4.png',
    },
    {
      imageAlt: 'Image 2',
      imageSrc: '../../../../assets/Slide-img-3.png',
    },
    {
      imageAlt: 'Image 3',
      imageSrc: '../../../../assets/Slide-img-4.png',
    },
    {
      imageAlt: 'Image 1',
      imageSrc: '../../../../assets/Slide-img-4.png',
    },
    {
      imageAlt: 'Image 2',
      imageSrc: '../../../../assets/Slide-img-3.png',
    },
    {
      imageAlt: 'Image 3',
      imageSrc: '../../../../assets/Slide-img-4.png',
    },
    {
      imageAlt: 'Image 1',
      imageSrc: '../../../../assets/Slide-img-4.png',
    },
    {
      imageAlt: 'Image 2',
      imageSrc: '../../../../assets/Slide-img-3.png',
    },
    {
      imageAlt: 'Image 3',
      imageSrc: '../../../../assets/Slide-img-4.png',
    },
  ];

  constructor(
    private readonly campaignService: CampaignsService,
    private readonly utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.campaignService.getAllCampaigns().subscribe({
      next: (result) => {
        this.campaigns = result;
      },
      error: (err) => {
        this.utilitiesService.showSpinner(false);
      },
    });
  }

  public isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  public setActive(index: number): void {
    this.activeIndex = index;
  }
}
