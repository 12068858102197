<div class="root">
  <!-- <div class="root-child"></div> -->
  <div class="story">
    <div class="bangladesh-stories">
      <h2 class="stories-of-change">
        {{ "Testimonials.stories" | translate }}
      </h2>
      <div class="read-inspiring-stories">
        {{ "Testimonials.read" | translate }}
      </div>
    </div>
    <div class="rectangle-parent">
      <div class="expertise-content-parent">
        <div class="expertise-content">
          <!-- class="logo-1-icon" -->
          <img
            loading="lazy"
            alt=""
            [ngSrc]="'../../../assets/images/home_page/hp-7/logo-1@2x.png'"
            width="111"
            height="32"
            
          />
        </div>
        <div class="shohays-expertise-enabled">
          {{ "Testimonials.shohays" | translate }}
        </div>
      </div>
      <div class="testimonial">
        <div class="testimonial-content">
          <div class="img-wrapper">
            <!-- class="testimonial-image-icon" -->
            <img
              loading="lazy"
              alt=""
              [ngSrc]="
                '../../../assets/images/home_page/hp-8/ellipse-19-1@2x.png'
              "
              height="64"
              width="64"
              
            />
          </div>

          <div class="testimonial-details">
            <div class="testimonial-name">
              <b class="ngo-representative-name">{{
                "Testimonials.NGO-Representative" | translate
              }}</b>
              <div class="position-or-title">
                {{ "Testimonials.Position" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="frame-parent">
    <div class="rectangle-parent">
      <div class="expertise-content-parent">
        <div class="expertise-content">
          <!-- class="logo-1-icon" -->
          <img
            loading="lazy"
            alt=""
            [ngSrc]="'../../../assets/images/home_page/hp-7/image-1@2x.png'"
            height="32"
            width="111"
            
          />
        </div>
        <div class="shohays-expertise-enabled">
          {{ "Testimonials.shohays2" | translate }}
        </div>
      </div>
      <div class="testimonial">
        <div class="testimonial-content">
          <div class="img-wrapper">
            <img
              class="testimonial-image-icon"
              loading="lazy"
              alt=""
              [ngSrc]="
                '../../../assets/images/home_page/hp-8/ellipse-19-2@2x.png'
              "
              width="64"
              height="64"
              
            />
          </div>

          <div class="testimonial-details">
            <div class="testimonial-name">
              <b class="ngo-representative-name">{{
                "Testimonials.NGO-Representative" | translate
              }}</b>
              <div class="position-or-title">
                {{ "Testimonials.Position" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rectangle-parent">
      <div class="frame-inner"></div>
      <div class="expertise-content-parent">
        <div class="expertise-content">
          <!-- class="logo-1-icon" -->
          <img
            style="width: 168px"
            loading="lazy"
            alt=""
            [ngSrc]="'../../../assets/images/home_page/hp-7/image-8@2x.png'"
            width="64"
            height="64"
            
          />
        </div>
        <div class="shohays-expertise-enabled">
          {{ "Testimonials.shohays3" | translate }}
        </div>
      </div>
      <div class="testimonial">
        <div class="testimonial-content">
          <div class="img-wrapper">
            <!-- class="testimonial-image-icon" -->
            <img
              loading="lazy"
              alt=""
              [ngSrc]="
                '../../../assets/images/home_page/hp-8/ellipse-19@2x.png'
              "
              width="64"
              height="64"
              
            />
          </div>

          <div class="testimonial-details">
            <div class="testimonial-name">
              <b class="ngo-representative-name">{{
                "Testimonials.NGO-Representative" | translate
              }}</b>
              <div class="position-or-title">
                {{ "Testimonials.Position" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
