<div class="root">
  <!-- <div class="root-child"></div> -->
  <div class="difference">
    <h1 class="ready-to-make">
      {{ "Contact-form.ready-to-make" | translate }}
    </h1>
    <div class="have-an-ngo">
      {{ "Contact-form.have-an-ngo" | translate }}
    </div>
  </div>
  <form class="ngos">
    <div class="buttons">
      <button class="blue-button-rounded-md">
        {{ "Contact-form.Message" | translate }}
      </button>
      <button class="white-button-rounded-md">
        {{ "Contact-form.Book" | translate }}
      </button>
    </div>

    <div class="inputs">
      <div class="input-labels">
        <a class="name">{{ "Contact-form.Name" | translate }}</a>
        <div class="input-values">
          <input
            class="your-name"
            [placeholder]="'Contact-form.placeHolder1' | translate"
            type="text"
          />
        </div>
      </div>
      <div class="input-labels">
        <div class="name">{{ "Contact-form.Email" | translate }}</div>
        <div class="input-values">
          <input
            class="your-name"
            [placeholder]="'Contact-form.placeHolder2' | translate"
            type="text"
          />
        </div>
      </div>
      <div class="input-labels">
        <div class="name">{{ "Contact-form.Organization" | translate }}</div>
        <div class="input-values">
          <input
            class="your-name"
            [placeholder]="'Contact-form.placeHolder3' | translate"
            type="text"
          />
        </div>
      </div>
      <div class="message-area">
        <div class="name">{{ "Contact-form.Messages" | translate }}</div>
        <textarea class="submission" rows="{6}" cols="{22}"> </textarea>
      </div>
      <div class="selection">
        <div class="select-one-of">
          {{ "Contact-form.select-one-of" | translate }}
        </div>
        <div class="radios">
          <div class="radio-options">
            <input
              class="radio-button-unchecked"
              type="radio"
              name="radioGroup-1"
            />

            <div class="already-have-an">
              {{ "Contact-form.already-have-an" | translate }}
            </div>
          </div>
          <div class="radio-options">
            <input
              class="radio-button-unchecked"
              type="radio"
              name="radioGroup-1"
            />

            <div class="already-have-an">
              {{ "Contact-form.Planning" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="blue-button-md">
      {{ "Contact-form.Submit" | translate }}
    </button>
  </form>
</div>
