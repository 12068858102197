import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hp7-partners',
  templateUrl: './hp7-partners.component.html',
  styleUrls: ['./hp7-partners.component.css']
})
export class Hp7PartnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
