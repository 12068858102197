import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { Component, OnInit } from '@angular/core';
import { CampaignsService } from 'src/app/shared/services/campaign/campaigns.service';

@Component({
  selector: 'app-all-campaign-list',
  templateUrl: './all-campaign-list.component.html',
  styleUrl: './all-campaign-list.component.scss',
})
export class AllCampaignListComponent implements OnInit {
  public campaignList: any[] = [];

  constructor(
    private readonly campaignService: CampaignsService,
    private readonly utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.getAllCampaigns();
  }

  private getAllCampaigns(): void {
    this.utilitiesService.showSpinner(true);
    this.campaignList = [];
    this.campaignService.getAllCampaigns().subscribe({
      next: (result: any) => {
        this.utilitiesService.showSpinner(false);
        this.campaignList = result;
      },
      error: (error: any) => {
        this.utilitiesService.showSpinner(false);
      },
    });
  }
}
