import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hp9-contact-form',
  templateUrl: './hp9-contact-form.component.html',
  styleUrls: ['./hp9-contact-form.component.css']
})
export class Hp9ContactFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
