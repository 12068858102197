import { Component } from '@angular/core';

@Component({
  selector: 'app-hp2-new-discover-shohay',
  templateUrl: './hp2-new-discover-shohay.component.html',
  styleUrl: './hp2-new-discover-shohay.component.scss'
})
export class Hp2NewDiscoverShohayComponent {

}
