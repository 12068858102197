<div class="ngo-list-body">
  <div class="hero">
    <div class="hero-left">
      <div class="hero-title">
        Discover the top NGO’s in the Shohay community
      </div>
      <div class="hero-description">
        Building a Robust Community of over 2,500 NGOs since 2024, Here are the
        Top-ranked Shohay Community NGOs of 2023.
      </div>
      <div class="ngo-search-wrapper">
        <input
          class="ngo-search-input"
          type="text"
          [(ngModel)]="ngoSearchQuery"
          placeholder="Enter NGO name here"
          (keyup.enter)="onSearchClick()"
        />

        <button class="blue-button-md" (click)="onSearchClick()">Search</button>
      </div>
    </div>
    <div class="hero-right">
      <div>
        <img
          src="../../../../../assets/images/home_page/hp-7/logo-1@2x.png"
          alt="brac"
          width="150px"
          height="50px"
        />
        <img
          src="../../../../../assets/images/home_page/hp-7/caritasbangladeshlogoe9e9263441seeklogo-2@2x.png"
          alt="caritas"
          width="100px"
          height="100px"
        />
      </div>
      <div>
        <img
          src="../../../../../assets/images/home_page/hp-7/image-1@2x.png"
          alt="care"
          width="150px"
          height="48.49px"
        />
        <img
          src="../../../../../assets/images/home_page/hp-7/image-2@2x.png"
          alt="asa"
          width="77.27px"
          height="100px"
        />
        <img
          src="../../../../../assets/images/home_page/hp-7/image-3@2x.png"
          alt="shakti"
          width="81.49px"
          height="100px"
        />
      </div>
      <div>
        <img
          src="../../../../../assets/images/home_page/hp-7/image-4@2x.png"
          alt="proshika"
          width="200px"
          height="43.41px"
        />
        <img
          src="../../../../../assets/images/home_page/hp-7/image-5@2x.png"
          alt="buro"
          width="91.67px"
          height="100px"
        />
      </div>
    </div>
  </div>

  <hr class="divider" />

  <div class="ngo-list">
    <div class="list-header">
      <div class="list-title">All NGO’s(2500+)</div>
      <!-- <div class="list-sort">Sort by</div> -->
    </div>

    <div class="ngo-card" *ngFor="let ngo of data">
      <div class="ngo-logo">
        <img
          src="../../../../../assets/images/home_page/hp-7/logo-1@2x.png"
          alt="logo"
        />
      </div>
      <div class="ngo-content">
        <div class="ngo-title">{{ ngo?.name }}</div>
        <div class="ngo-info">
          <div class="ngo-address">
            <img src="../../../../../assets/map-pin.svg" alt="pin" />
            <span>{{ ngo?.address }}</span>
          </div>
          <div class="ngo-website">
            <img src="../../../../../assets/globe.svg" alt="globe" />
            <span>website.net</span>
          </div>
        </div>
        <div class="ngo-description">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quibusdam
          sapiente, similique quas necessitatibus consequatur, qui aspernatur
          veritatis incidunt ea rem repudiandae ullam esse maxime?
        </div>
        <div class="ngo-action-buttons">
          <button class="blue-button-md" (click)="goToNgoPage(ngo)">
            View Details</button
          ><button class="yellow-button-md">Donate</button>
        </div>
      </div>
    </div>

    <div class="paginator">
      <button
        class="prev-button"
        (click)="goToPrevPage()"
        [disabled]="currentPage === 1"
      >
        Prev
      </button>
      <ng-container *ngFor="let page of pages">
        <button
          *ngIf="page !== '...'"
          (click)="goToPage(page)"
          [class.active]="currentPage === page"
        >
          {{ page }}
        </button>
        <span class="fw-bold" *ngIf="page === '...'">...</span>
      </ng-container>
      <button
        class="next-button"
        (click)="goToNextPage()"
        [disabled]="currentPage === totalPages"
      >
        Next
      </button>
    </div>
  </div>
</div>
