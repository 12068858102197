import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { HomePageComponent } from './home-page-components/home-page/home-page.component';
import { NgoListComponent } from './ngo/ngo-list/ngo-list.component';
import { NgoPageComponent } from './ngo/ngo-page/ngo-page.component';
import { NgoSearchComponent } from './ngo/ngo-search/ngo-search.component';
import { ArticleNewsComponent } from './article-news/article-news.component';
import { NewsComponent } from './news/news.component';
import { DonationComponent } from './donation/donation.component';
import { AllCampaignListComponent } from './all-campaign-list/all-campaign-list.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    pathMatch: 'full',
  },
  { path: 'search-blog', component: GlobalSearchComponent, pathMatch: 'full' },
  { path: 'search-ngo', component: NgoSearchComponent, pathMatch: 'full' },
  { path: 'find-ngo', component: NgoListComponent, pathMatch: 'full' },
  { path: 'ngo', component: NgoPageComponent, pathMatch: 'full' },
  { path: 'article/:id', component: ArticleNewsComponent, pathMatch: 'full' },
  { path: 'news/:id', component: NewsComponent, pathMatch: 'full' },
  { path: 'donation', component: DonationComponent, pathMatch: 'full' },
  {
    path: 'all-relief-campaigns',
    component: AllCampaignListComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MarketingRoutingModule {}
