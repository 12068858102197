import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hp6-usp',
  templateUrl: './hp6-usp.component.html',
  styleUrls: ['./hp6-usp.component.css']
})
export class Hp6UspComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
