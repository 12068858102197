import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hp8-testimonials',
  templateUrl: './hp8-testimonials.component.html',
  styleUrls: ['./hp8-testimonials.component.css']
})
export class Hp8TestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
