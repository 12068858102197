import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleBodyModel } from 'src/app/shared/model/article.model';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-article-news',
  templateUrl: './article-news.component.html',
  styleUrl: './article-news.component.scss',
})
export class ArticleNewsComponent implements OnInit {
  public date: string = '';
  public time: string = '10 min read';
  public titleImageUrl: string =
    '../../../../assets/images/article-news/image 216.png';
  public tableOfContent: {
    contentID: string;
    contentTitle: string;
    contentImage: string;
    content: string;
  }[] = [
    {
      contentID: '1',
      contentTitle: 'Introduction',
      contentImage: '',
      content: '',
    },
    {
      contentID: '2',
      contentTitle:
        'Navigating the Interplay: NGOs, Civil Society, and State Relations in Development Initiatives',
      contentImage: '../../../../assets/images/article-news/image 217.png',
      content: `Some NGOs are created for mixed purposes: partly not-for-profit services essentially in the private sector; partly recycling profit earnings for social development (such as from micro-banking); partly therefore for conscientisation, awareness raising, and functional literacy to enable clients to participate democratically or to compete more strongly and effectively in markets, or to access rights and entitlements in relation to the state; partly to advocate and lobby on behalf of clients, whether in relation to the state or in the domain of public opinion. In other words, as "development" NGOs with plural missions, they can be half in civil society and half out of it as a reflection of their practice, if not of their rhetoric.
             This complexity makes generalised conclusions and judgements difficult. \n\n How was Proshika, for example, to be understood with its "walking on two legs" mission: fighting for rights and inclusion on the one hand, while filling bellies through income-generating services on the other. Or take legal rights organisations, such as BLAST, employing lawyers to work with cases or clients. How are they to be classified? Can their individual case work nevertheless set wider precedents in law and thereby establish principles regarding the state? Other NGOs have shunned micro-banking and focused only upon "social development," like Nijera Kori or GSS, with a mission of empowerment in the interstices between the mode of production and the state (for example, acquisition of khas land). But if, like BRAC, they are providing Non-Formal Primary Education (NFPE) as a substitute for state provision of inclusive primary education, then does the activity of provision lie outside civil society (as a de facto franchised service) while the outcome eventually lies within civil society if, for example, empowerment of youth has been enhanced? An overall conclusion from these classificatory dilemmas (and there are many further examples) is the danger of simply eliding the concept of NGO with the concept of civil society.`,
    },
    {
      contentID: '3',
      contentTitle: 'Conclusion',
      contentImage: '',
      content: '',
    },
  ];

  public tags: string[] = [
    'Development',
    'NGOs in Bangladesh',
    'Primary Education Bangladesh',
  ];
  public commentSortingType: string[] = ['Newest', 'Oldest'];
  public isButtonDisabled: boolean = true;
  public autoResizeEnabled: boolean = true;

  public article: ArticleBodyModel = new ArticleBodyModel();
  public activeSectionId: string = 'Table of Content';

  private articleNewsOriginalList: any[] = [];
  public articleNewsList: any[] = [];
  private baseUrl: string = '../../../../../assets/images/home_page/hp-2/';
  private imageList: any[] = [
    `${this.baseUrl}Rectangle_30075.png`,
    `${this.baseUrl}Rectangle_30075_1.png`,
    `${this.baseUrl}Rectangle_30075_2.png`,
    `${this.baseUrl}rectangle-30075@2x.png`,
  ];
  public articleId: string = '';

  constructor(
    private readonly shohayBlogService: ShohayBlogService,
    private activatedRoute: ActivatedRoute,
    private readonly utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.articleId = this.activatedRoute.snapshot.paramMap.get('id')!;
    this.loadArticleById(this.articleId);
    this.loadArticleNews();
  }

  private loadArticleById(articleId: string): void {
    this.shohayBlogService.getArticleById(articleId).subscribe(
      (result: any) => {
        this.onSuccess(result);
      },
      (error) => {
        this.onError(error);
      }
    );
  }

  private onSuccess(result: any) {
    this.article = new ArticleBodyModel(result);
    this.getDateTime(String(this.article.publishedAt));
    this.updateTableOfContent(
      this.article.introduction,
      this.article.conclusion
    );
  }

  private onError(error: any) {
    this.utilitiesService.showSwalWithToast(
      'ERROR',
      error?.error?.message,
      'error',
      false,
      8000
    );
  }

  private getDateTime(publishedDate: string): void {
    const date = new Date(publishedDate);
    this.date = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(date);
  }

  private loadArticleNews(): void {
    this.shohayBlogService.getArticleNews().subscribe((result) => {
      this.articleNewsOriginalList = result
        .splice(0, 4)
        .map((element: any, index: number) => {
          return { imageUrl2: this.imageList[index], ...element };
        });
      this.articleNewsList = JSON.parse(
        JSON.stringify(this.articleNewsOriginalList)
      );
    });
  }

  private updateTableOfContent(
    introductionContent: string,
    conclusionContent: string
  ): void {
    const introductionSection = this.tableOfContent.find(
      (section) => section.contentID === '1'
    );
    if (introductionSection) {
      introductionSection.content = introductionContent;
    }

    const conclusionSection = this.tableOfContent.find(
      (section) => section.contentID === '3'
    );
    if (conclusionSection) {
      conclusionSection.content = conclusionContent;
    }
  }

  public onChange(contentID: string) {
    this.activeSectionId = contentID;
    this.scrollToSection(contentID);
  }

  public scrollToSection(contentID: string) {
    this.activeSectionId = contentID;
    const element = document.getElementById(contentID);
    if (element) {
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  public navigateToArticle(id: string): void {
    window.location.href = `/article/${id}`;
  }

  //progressbar
  public getScrollProgress(): number {
    const scrollPosition = window.scrollY || window.pageYOffset;
    const totalHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    return (scrollPosition / totalHeight) * 100;
  }
}
