import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import {
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFilterBuilderModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxMenuModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxSchedulerModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
  DxTreeListModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule,
   DxTreeViewModule
} from 'devextreme-angular';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    FormsModule,
    DxMenuModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxButtonModule,
    DxLoadPanelModule,
    DxDropDownBoxModule,
    DxDataGridModule,
    DxPopupModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxTooltipModule,
    DxFileUploaderModule,
    DxSchedulerModule,
    DxFormModule,
    DxValidationSummaryModule,
    DxTagBoxModule,
    DxFilterBuilderModule,
    DxTreeListModule,
    DxSwitchModule,
    DxNumberBoxModule,
    DxTabPanelModule,
    DxRadioGroupModule,
    DxAccordionModule,
    DxLoadIndicatorModule,
    DxValidationGroupModule,
    DxScrollViewModule,
    DxTreeViewModule
  ],
})
export class DevExtremeModule {}
