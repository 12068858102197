<div class="root row">
  <section class="homepage col-sm-12">
    <!-- <div class="homepage-child"></div> -->
    <div class="row">
      <div class="empty col-md-5 col-sm-12">
        <div class="header">
          <h2 class="find-your-cause">{{ "Service.find-your" | translate }}</h2>
          <div class="browse-our-curated">
            {{ "Service.browse-our" | translate }}
          </div>
          <div class="frame-group">
            <div class="learn-how-to-start-an-ngo-wrapper">
              {{ "Service.learn-how" | translate }}
            </div>
            <img
              class="arrow-right-alt-icon"
              loading="lazy"
              alt=""
              src="../../../assets/images/icon/arrow-right-alt.svg"
            />
          </div>
        </div>
      </div>
      <div class="rectangle-parent col-md-7 col-sm-12">
        <!-- <div class="becff-container-child"></div> -->
        <img
          class="e2832417f-icon"
          loading="lazy"
          alt=""
          [ngSrc]="'../../../assets/images/home_page/hp-3/c499ea5824@2x.png'"
          height="354"
          width="700"
        />
      </div>
    </div>
  </section>
  <section class="journey-content-parent col-sm-12">
    <div class="row gap-3">
      <div class="journey-content col-md-6 col-sm-12">
        <div class="start-your-journey-parent">
          <h2 class="start-your-journey">
            {{ "Service.start-your" | translate }}
          </h2>
          <div class="thinking-of-starting">
            {{ "Service.thinking-of" | translate }}
          </div>
          <div class="frame-group">
            <div class="learn-how-to-start-an-ngo-wrapper">
              {{ "Service.learn-how-to" | translate }}
            </div>
            <img
              class="arrow-right-alt-icon"
              loading="lazy"
              alt=""
              src="../../../assets/images/icon/arrow-right-alt.svg"
            />
          </div>
        </div>
        <div class="becff-container-wrapper">
          <div class="becff-container">
            <div class="becff-container-child"></div>
            <img
              class="e2832417f-icon"
              loading="lazy"
              alt=""
              [ngSrc]="
                '../../../assets/images/home_page/hp-3/2e2832417f@2x.png'
              "
              height="254"
              width="154"
            />
          </div>
        </div>
      </div>
      <div class="journey-content col-md-6 col-sm-12">
        <div class="start-your-journey-parent">
          <h2 class="start-your-journey">
            {{ "Service.Strive-for" | translate }}
          </h2>
          <div class="thinking-of-starting">
            {{ "Service.Access-essential" | translate }}
          </div>
          <div class="frame-group">
            <div class="learn-how-to-start-an-ngo-wrapper">
              {{ "Service.Explore-Resources" | translate }}
            </div>
            <img
              class="arrow-right-alt-icon"
              loading="lazy"
              alt=""
              src="../../../assets/images/icon/arrow-right-alt.svg"
            />
          </div>
        </div>
        <div class="becff-container-wrapper">
          <div class="becff-container">
            <div class="becff-container-child"></div>
            <img
              class="e2832417f-icon"
              loading="lazy"
              alt=""
              [ngSrc]="
                '../../../assets/images/home_page/hp-3/799be4cf3f@2x.png'
              "
              width="154"
              height="254"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
