import { UtilitiesService } from './../../../shared/services/utilities/utilities.service';
import { DonationService } from './../../../shared/services/donation/donation.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmergencyDonationModel } from 'src/app/shared/model/emergency-donation.model';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { CampaignsService } from 'src/app/shared/services/campaign/campaigns.service';

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrl: './donation.component.scss',
})
export class DonationComponent implements OnInit {
  public campaign: any;
  public images: any[];
  private currentIndex: number = 0;
  // private imageIndex: boolean[];
  // private previousIndex: number = 0;

  public donationModel: EmergencyDonationModel = new EmergencyDonationModel();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly donationService: DonationService,
    private readonly utilitiesService: UtilitiesService,
    private readonly campaignService: CampaignsService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const campaign_id = params['id'];

      if (campaign_id) {
        this.utilitiesService.showSpinner(true);
        this.campaignService.getAllCampaigns().subscribe({
          next: (result) => {
            this.utilitiesService.showSpinner(false);

            this.images = result[campaign_id]?.img_url;
            this.campaign = result[campaign_id];
            // this.imageIndex = Array(this.images?.length).fill(false);
            // this.imageIndex[0] = true;
            this.startImageSlider();

            this.donationModel.campaign_id = this.campaign.id;
          },
          error: (err) => {
            this.utilitiesService.showSpinner(false);
          },
        });
      } else {
        this.router.navigateByUrl('/');
      }
    });
  }

  public onSubmit(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.donationModel.amount = this.donationModel.amount.toString();
      this.utilitiesService.showSpinner(true);
      this.donationService
        .emergencyDonationRequest(this.donationModel)
        .subscribe({
          next: (result) => {
            this.utilitiesService.showSpinner(false);
            ValidationEngine.resetGroup('validationGrp');
          },
          error: (err) => {
            this.utilitiesService.showSpinner(false);
          },
        });
    }
  }

  private startImageSlider() {
    setInterval(() => {
      // this.imageIndex[this.previousIndex] = false;
      // this.previousIndex = this.currentIndex;
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      //this.imageIndex[this.currentIndex] = true;
    }, 5000); // Change image every 5 seconds
  }

  get backgroundImage(): string {
    return `url(${this.images[this.currentIndex]?.url})`;
  }
  // public currentImage(index: number): boolean {
  //   return this.imageIndex[index];
  // }
}