import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewsModel } from 'src/app/shared/model/news.model';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
})
export class NewsComponent {
  public autoResizeEnabled: boolean = true;
  public value: string = 'Share your thoughts...';
  public isButtonDisabled: boolean = true;
  public newsModel: NewsModel = new NewsModel();
  private id: string = '';
  public publisherLogo: string =
    '../../../../assets/images/icon/shohay-publisher.svg';
  public publisher: string = 'Shohay Team';
  public date: string = '';
  public time: string = '';
  public titleImageUrl: string = '';
  public allPartContent: string[] = [];
  public tags: string[] = [
    'Development',
    'NGOs in Bangladesh',
    'Primary Education Bangladesh',
  ];

  public others: {
    type: string;
    image: string;
    subtitle: string;
    title: string;
  }[] = [
    {
      type: 'Article',
      image: '../../../../../assets/images/home_page/hp-2/Rectangle_30075.png',
      subtitle: 'Primary education Bangladesh',
      title: 'Development NGOs: Arm of the state or part of civil society?',
    },
    {
      type: 'Article',
      image:
        '../../../../../assets/images/home_page/hp-2/Rectangle_30075_1.png',
      subtitle: 'Development Activities in Bangladesh',
      title:
        ' Local NGOs for leadership role thru’ equal partnership with intl’ orgs',
    },
    {
      type: 'Article',
      image:
        '../../../../../assets/images/home_page/hp-2/Rectangle_30075_2.png',
      subtitle: 'Rohingya Fund | Rohingya Crisis',
      title: 'Rohingya Fund Use: INGO Forum welcomes probe',
    },
    {
      type: 'News',
      image: '../../../../../assets/images/home_page/hp-2/Rectangle_30075.png',
      subtitle: 'Primary education Bangladesh',
      title: 'Development NGOs: Arm of the state or part of civil society?',
    },
  ];
  public simpleProducts: string[] = ['Newest', 'Oldest'];

  constructor(
    private readonly shohayBlogService: ShohayBlogService,
    private readonly route: ActivatedRoute,
    private readonly utilitiesService: UtilitiesService,
    private readonly spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.loadNewsById(this.id);
  }

  private loadNewsById(articleId: string): void {
    this.spinner.show();
    this.shohayBlogService.getArticleById(articleId).subscribe(
      (result) => {
        this.onSuccess(result);
      },
      (error) => {
        this.onError(error);
      }
    );
  }

  private onSuccess(response: any): void {
    this.newsModel = new NewsModel(response);
    this.missingDataFromApiHandling(
      response.introduction,
      response.conclusion,
      response.imageUrl
    );
    this.getDateTime(String(this.newsModel.publishedAt));
    this.spinner.hide();
  }

  private missingDataFromApiHandling(
    introduction: string | null,
    conclusion: string | null,
    titleImage: string | null
  ): void {
    if (introduction) {
      this.allPartContent[0] = introduction;
    } else {
      this.allPartContent[0] =
        'Alongside government organisations, non-governmental ones will also have to work for building a developed and Smart Bangladesh by 2041, Akhter Hossain, principal coordinator for SDG Affairs at the Prime Ministers Office, has said.';
    }
    if (conclusion) {
      this.allPartContent[1] = conclusion;
      const paragraphs = this.allPartContent[1]
        .split(/\n\s*\n/)
        .filter((para) => para.trim().length > 0);
      this.allPartContent.splice(1, 1, ...paragraphs);
    } else {
      this.allPartContent[1] = `Bangladesh has made progress in all indicators of the Sustainable Development Goals (SDGs). To sustain the progress, he emphasised on greater dynamism and cooperation in the activities of all public and private sector organisations,

      Akhter Hossain, as the chief guest, was addressing a seminar, titled "Role of NGOs in the Implementation of Sustainable Development Goals (SDGs) in Bangladesh", at PKSF Bhaban in the capitals Agargaon today.

      Akhter Hossain, as the chief guest, was addressing a seminar, titled "Role of NGOs in the Implementation of Sustainable Development Goals (SDGs) in Bangladesh", at PKSF Bhaban in the capitals Agargaon today.

      Chairing the event, Qazi Kholiquzzaman Ahmad, chairman of PKSF (Palli Karma Sahayak Foundation), said that Bangabandhu Sheikh Mujibur Rahman struggled throughout his life to bring smiles to the faces of the poor and the disadvantaged.

      Mentioning that 27% of the population of Bangladesh is in the age group of 25-29 years, he urged everyone to work together to provide the young people with the required education, training, and employment prospects.

      "The main philosophy behind all the work of PKSF is to bring smiles to the faces of the disadvantaged people," he said.','In her welcome speech, Managing Director of PKSF, Nomita Halder said, "PKSF is working to achieve most of the SDGs, starting from hunger and poverty alleviation to establishing peace and harmony.

      She added that the foundation has been playing a significant role in the socio-economic progress of Bangladesh for the last three decades.

      Monirul Islam, additional secretary (SDG Affairs) at Prime Minister's Office, gave a presentation on SDGs, while Md Mashiar Rahman, deputy managing director of PKSF gave a presentation on "Role of NGOs in SDGs Implementation" in the seminar,
    
    `;
      const paragraphs = this.allPartContent[1]
        .split(/\n\s*\n/)
        .filter((para) => para.trim().length > 0);
      this.allPartContent.splice(1, 1, ...paragraphs);
    }

    if (titleImage) {
      this.titleImageUrl = titleImage;
    } else {
      this.titleImageUrl = '../../../../assets/images/news/news-title.png';
    }
  }

  private onError(error: Error) {
    this.spinner.hide();
    this.utilitiesService.showSwalWithToast(
      'ERROR',
      `Error: ${error.message}`,
      'error',
      false,
      8000
    );
  }

  private getDateTime(publishedDate: string): void {
    const date = new Date(publishedDate);
    this.date = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(date);
    const allText = this.allPartContent.join(' ');
    const wordsArray = allText.split(/\s+/).filter((word) => word.length > 0);
    const totalWords = wordsArray.length;
    const wordsPerMinute = 50;
    const totalMinutes = totalWords / wordsPerMinute;
    const minutes = Math.floor(totalMinutes);
    this.time = `${minutes} minute(s) read`;
  }

  public getScrollProgress(): number {
    const scrollPosition = window.scrollY || window.pageYOffset;
    const totalHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    return (scrollPosition / totalHeight) * 100;
  }
}
