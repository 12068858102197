import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-ngo-page',
  templateUrl: './ngo-page.component.html',
  styleUrls: ['./ngo-page.component.css'],
})
export class NgoPageComponent implements OnInit {
  private elastic_id: string = null;
  private ngoParams: any;
  public ngo: any = null;
  public articleNewsList: any[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly ngoService: NgoService,
    private readonly shohayBlogService: ShohayBlogService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const value = params['ngo'];
      if (value) {
        this.ngoParams = JSON.parse(params['ngo']);

        if (this.ngoParams) {
          this.elastic_id = this.ngoParams.id;
          this.getNgo();
        }
      }
      this.getArticleNews();
    });
  }

  private getNgo(): void {
    this.ngoService
      .getNgoByElasticId(this.elastic_id)
      .subscribe((response: any) => {
        if (response) {
          this.ngo = response;
        } else {
          this.ngo = this.ngoParams;
        }
      });
  }

  private getArticleNews(): void {
    this.shohayBlogService.getArticleNews().subscribe((result) => {
      this.articleNewsList = result.splice(0, 5);
    });
  }

  public formatDate(date: string): string {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(new Date(date));
  }
}
