import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css'],
})
export class GlobalSearchComponent implements OnInit {
  searchValue: string = '';
  resultType: string = 'Article/News';
  public searchResults: {
    title: string;
    topic: string;
    id: string;
    type: string;
  }[] = [];
  // private articleNewsOriginalList: any[] = [];
  constructor(
    private router: Router,
    private readonly shohayBlogService: ShohayBlogService
  ) {}
  ngOnInit() {
    // this.searchValue = 'brac';
    this.shohayBlogService.getArticleNews().subscribe((result) => {
      this.searchResults = result.map((element: any) => {
        return {
          title: element?.title,
          topic: element?.topic,
          id: element?.id,
          type: element?.type,
        };
      });
    });
    setTimeout(() => {
      console.log(this.searchResults);
    }, 5000);
  }

  get filteredResults(): {
    title: string;
    topic: string;
    id: string;
    type: string;
  }[] {
    return this.searchResults.filter(
      (item) =>
        item.title.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        item.topic.toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }

  goToNgoPage(ngoName: string): void {
    const queryParams = { name: JSON.stringify({ name: ngoName }) };
    this.router.navigate(['/ngo'], { queryParams });
  }

  public goToArticleNews(id: string, type: string): void {
    console.log(type);
    if (type === 'Article') {
      this.router.navigate(['/article', id]);
    } else {
      this.router.navigate(['/news', id]);
    }
  }

  public onSearchClick(): void {
    // if (!this.ngoSearchQuery) {
    //   return;
    // }
    // this.router.navigateByUrl('/search-ngo?query=' + this.ngoSearchQuery);
  }

  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/find_icon.svg',
    stylingMode: 'text',
  };
}
