import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DevExtremeModule } from 'src/app/shared/modules/devExtreme.module';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { HomePageComponent } from './home-page-components/home-page/home-page.component';
import { Hp1HeroComponent } from './home-page-components/hp1-hero/hp1-hero.component';
import { Hp2ArticleNewsComponent } from './home-page-components/hp2-article-news/hp2-article-news.component';
import { Hp3ServiceComponent } from './home-page-components/hp3-service/hp3-service.component';
import { Hp4SignUpComponent } from './home-page-components/hp4-sign-up/hp4-sign-up.component';
import { Hp5NgoToolsComponent } from './home-page-components/hp5-ngo-tools/hp5-ngo-tools.component';
import { Hp6UspComponent } from './home-page-components/hp6-usp/hp6-usp.component';
import { Hp7PartnersComponent } from './home-page-components/hp7-partners/hp7-partners.component';
import { Hp8TestimonialsComponent } from './home-page-components/hp8-testimonials/hp8-testimonials.component';
import { Hp9ContactFormComponent } from './home-page-components/hp9-contact-form/hp9-contact-form.component';
import { MarketingRoutingModule } from './marketing-routing.module';
import { NgoListComponent } from './ngo/ngo-list/ngo-list.component';
import { NgoPageComponent } from './ngo/ngo-page/ngo-page.component';
import { NgoSearchComponent } from './ngo/ngo-search/ngo-search.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { ArticleNewsComponent } from './article-news/article-news.component';
import { Hp2NewDiscoverShohayComponent } from './home-page-components/hp2-new-discover-shohay/hp2-new-discover-shohay.component';
import { TranslateModule } from '@ngx-translate/core';
import { NewsComponent } from './news/news.component';
import { DonationComponent } from './donation/donation.component';
import { DonationButtonsComponent } from './home-page-components/donation-buttons/donation-buttons.component';
import { DxGalleryModule } from 'devextreme-angular';
import { AllCampaignListComponent } from './all-campaign-list/all-campaign-list.component';

@NgModule({
  declarations: [
    HomePageComponent,
    Hp1HeroComponent,
    Hp2ArticleNewsComponent,
    Hp3ServiceComponent,
    Hp4SignUpComponent,
    Hp5NgoToolsComponent,
    Hp6UspComponent,
    Hp7PartnersComponent,
    Hp8TestimonialsComponent,
    Hp9ContactFormComponent,
    NgoSearchComponent,
    NgoListComponent,
    GlobalSearchComponent,
    NgoPageComponent,
    ArticleNewsComponent,
    Hp2NewDiscoverShohayComponent,
    NewsComponent,
    DonationComponent,
    DonationButtonsComponent,
    AllCampaignListComponent,
  ],
  imports: [
    CommonModule,
    MarketingRoutingModule,
    DevExtremeModule,
    DxGalleryModule,
    FormsModule,
    SharedModule,
    // TranslateModule,
    TranslateModule.forChild(),
    NgOptimizedImage
  ],
  exports: [
    TranslateModule,
    // MarketingHeaderComponent
  ],
})
export class MarketingModule {}
