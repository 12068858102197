<div class="progress-container">
  <div class="progress-bar" [style.width]="getScrollProgress() + '%'"></div>
</div>
<div class="article_body">
  <div class="title_img_section">
    <div class="title_body">
      <div class="type">
        <button class="type_button">
          {{ newsModel.type }}
        </button>
      </div>
      <div class="title">
        <p class="fw-bold">{{ newsModel.title }}</p>
      </div>
      <div class="details">
        <div class="logo-publisher">
          <img
            [src]="publisherLogo"
            alt="Article Image"
            class="article-title-image"
          />
        </div>
        <!-- <div class="publisher">
          <p>BY {{ publisher }}</p>
        </div> -->
        <div class="date-time">
          <div class="date">
            <ul>
              <li>
                {{ date }}
              </li>
            </ul>
          </div>
          <div class="time">
            <ul>
              <li>
                {{ time }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="img_body">
      <!-- [src]="newsModel2.imageUrl" -->
      <img
        [src]="titleImageUrl"
        alt="Article Image"
        class="article-img-title"
      />
    </div>
  </div>
  <div class="article_body_section">
    <div class="content-section">
      <div class="first-part-content">
        <p *ngFor="let para of allPartContent; let i = index" class="paragraph">
          {{ para }}
        </p>
      </div>
      <div class="tag-section">
        <div class="type-tag">
          <button class="tag-button-article" *ngFor="let tag of tags">
            {{ tag }}
          </button>
        </div>
      </div>
      <div class="article_comment_section">
        <section class="comment-item">
          <div class="comment-item1">
            <div class="comment-item2">
              <div class="message-square">
                <img
                  src="../../../../assets/images/article-news/message-square.svg"
                  alt="msg icon"
                  class="msg-icon"
                />
              </div>

              <a class="comments">0 Comments</a>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Sort by:</div>
              <div class="dx-field-value">
                <!-- placeholder="Choose Product" -->
                <dx-select-box
                  [items]="simpleProducts"
                  [inputAttr]="{ 'aria-label': 'Product With Placeholder' }"
                  [showClearButton]="true"
                  [elementAttr]="{ class: 'custom-text-box' }"
                ></dx-select-box>
              </div>
            </div>
          </div>
          <div class="auth-prompt">
            <div class="login-or-register">
              Login or register to join the conversation.
            </div>
            <div class="auth-actions">
              <!-- component-1  -->
              <button class="blue-button-auth-lg">Create an Account</button>
              <button class="yellow-button-md">Log in</button>
            </div>
          </div>
        </section>
        <div class="user-interaction">
          <div class="user-profile">
            <img
              class="account-circle-icon"
              alt=""
              src="../../../../assets/images/news/account-circle.svg"
            />
          </div>
          <div class="share-prompt">
            <!-- [maxLength]="maxLength" -->
            <dx-text-area
              id="text-area"
              height="50"
              [readOnly]="true"
              [(value)]="value"
              [width]="'100%'"
              [(autoResizeEnabled)]="autoResizeEnabled"
              [inputAttr]="{ 'aria-label': 'Notes' }"
            >
            </dx-text-area>
          </div>
        </div>
        <button class="blue-button-sm" [disabled]="isButtonDisabled">
          Post
        </button>
      </div>
      <!-- all comment section -->
      <section class="root2">
        <div class="user-profile">
          <img
            class="account-circle-icon"
            alt=""
            src="../../../../assets/images/news/account-circle.svg"
          />
        </div>
        <div class="comment-person-name">
          <div class="person-date">
            <a class="name">Sanjoy Dutta</a>
            <div class="date-2">13 Jul 2024 at 6:08 PM</div>
          </div>
          <div class="comment-body">
            The main philosophy behind all the work of NGO is to bring smiles to
            the faces of the disadvantaged people.
          </div>
          <div class="actions1">
            <div class="icons2">
              <img
                class="corner-down-left-icon1"
                loading="lazy"
                alt=""
                src="../../../../assets/images/news/corner-down-left.png"
              />

              <div class="replay1">Replay</div>
            </div>
            <div class="icons3">
              <img
                class="thumbs-up-icon1"
                loading="lazy"
                alt=""
                src="../../../../assets/images/news/thumbs-up.png"
              />

              <div class="like1">Like</div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="signup-section">
      <div class="share-social">
        <p class="share">Share</p>
        <div class="social-icon">
          <img
            src="../../../../assets/images/article-news/icon/2023_Facebook_icon 1.svg"
            class="ico-social"
          />
          <img
            src="../../../../assets/images/article-news/icon/Vector.svg"
            class="ico-social"
          />
          <img
            src="../../../../assets/images/article-news/icon/link.svg"
            class="linkedIN"
          />
          <img
            src="../../../../assets/images/article-news/icon/Base icons.svg"
            class="ico-social"
          />
        </div>
      </div>
      <div #signUpDiv class="signup-for-donation">
        <div>
          <p class="Looking-for-donation">
            Looking for donation for your next NGO project?
          </p>
        </div>
        <div class="Image-div">
          <img
            src="../../../../assets/images/article-news/2e2832417f.png"
            class="image-signup"
          />
        </div>
        <div class="sign-up-button">
          <button class="yellow-button-md">Sign Up Now</button>
        </div>
      </div>
    </div>
  </div>

  <div class="root">
    <div class="news-container">
      <h1 class="stay-updated-with">You might also be interested in</h1>
    </div>
    <section class="news-cards">
      <div class="cards" *ngFor="let other of others">
        <div
          class="rectangle-parent"
          [ngStyle]="{ 'background-image': 'url(' + other.image + ')' }"
        >
          <img class="frame-child" alt="" [src]="other.image" />
          <div class="article-wrapper">
            <div class="article">{{ other.type }}</div>
          </div>
        </div>
        <div class="subtitle-section">
          {{ other.subtitle }}
        </div>
        <div class="title-section">
          {{ other.title }}
        </div>
        <div class="frame-group">
          <a src="#"> Read More</a>
          <img
            class="arrow-right-alt-icon"
            loading="lazy"
            alt=""
            src="../../../../../assets/images/icon/arrow-right-alt.svg"
          />
        </div>
      </div>
    </section>
  </div>
</div>
